import * as React from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import { AdaPro } from '@/images/support/index'
import { DetailHeader, DetailStart, DetailFaq } from '@/components/Support/index'
import { useGTMInit} from '@/hooks/useGTM.js'
const Index = () => {
  React.useEffect(()=>{
    useGTMInit('support','one')
  },[])
  return (
    <Layout  menuBlack={true}>
      <SEO page="Support" />
      <DetailHeader pro='onepro' />
      <DetailStart updateLink='https://www.xreal.com/ota/' updateLinkOut={true}  />
      <DetailFaq type='one' />

    </Layout>
  )
}

export default Index